<template>
  <div>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/event_img/gbcc-town-event1.png' @click='goToGbccTown' class='cursor-pointer'/>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/event_img/gbcc-town-event2.png' @click='goToTutorial' class='cursor-pointer'/>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/event_img/gbcc-town-event3.png' />
    <div class='bg-white py-4 row-centered justify-center gap-x-4'>
      <input
        type='text'
        class='border-2 border-solid border-gray-300 rounded-full w-1/2 py-4 px-4 text-center'
        placeholder='Enter Number!'
        v-model='answerNumber' />
      <button
        class='border rounded-full py-4 px-12 text-white uppercase text-base font-semibold'
        :style='backgroundEventStyle'
        @click='submitAnswer'>
        Submit
      </button>
    </div>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/event_img/gbcc-town-event4.png' @click='goToGbccTown' class='cursor-pointer' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sponsorApi from '@/api/v1/sponsors'

export default {
  name: 'PromotionEventFlyerGbccTown',
  components: {

  },
  data () {
    return {
      answerNumber: '',
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId'
    ]),
    backgroundEventStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
  },
  methods: {
    goToTutorial () {
      window.open('https://youtu.be/tTNOLrszHhY', '_blank')
    },
    goToGbccTown () {
      window.open('https://app.gather.town/app/VKYitxC12zNCvJrR/GBCC%20Town', '_blank')
    },
    submitAnswer () {
      this.$confirm(`Submit your answer: ${this.answerNumber} ?`, {
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(() => {
        sponsorApi.postBoothVisitor(91, 'gbcc_event', this.answerNumber, this.showingEventId).then(() => {
          this.answerNumber = ''
            this.$message({
              type: 'success',
              message: 'answer successfully submitted'
            })
        })
      }).catch(() => {})
    }
  },
  mounted () {

  },
}
</script>

<style lang='scss' scoped>

</style>
