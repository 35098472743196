<template>
  <div>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/event_img/sponsor-quiz-event.png'
      @click='goToExhibition' class='cursor-pointer' />
  </div>
</template>

<script>
  
export default {
  name: 'PromotionEventFlyerExhibitionSponsorQuiz',
  methods: {
    goToExhibition () {
      window.open('exhibition', '_blank')
    },
  },
}
</script>
