<template>
  <div class='relative flex flex-col lg:flex-row justify-start lg:justify-between items-start mt-4 lg:mt-8'>
    <div class='flex-shrink-0 flex-grow-0'>
      <h1 class='px-4 py-4 lg:py-8 text-2xl font-medium capitalize mb-4'>events</h1>
      <div class='w-screen lg:w-80 overflow-x-auto flex flex-row lg:flex-col justify-start'>
        <div v-for='(promotionNavigation, index) in promotionalEventsList'
          :key='`promotion-event-${promotionNavigation.name}`'
          class='promotion-navigation-tab px-2 lg:px-4 py-3 lg:py-6 cursor-pointer mb-2'
          style='min-width: 10rem;'
          :class='isSelectedPromotionalEvent(index)'
          @click='selectPromotion(index)'>
          <h2 class='text-normal lg:text-2xl pb-1' style='color: #380622;'>{{promotionNavigation.name}}</h2>
          <h3 class='text-sm lg:text-normal opacity-50'>{{promotionNavigation.dateString}}</h3>
        </div>
      </div>
    </div>
    <div class='flex-grow min-h-screen p-2 lg:p-8 rounded'
      style='background-color: #F6E7F1;'>
      <component :is='selectedFlyerComponent' />
    </div>
  </div>
</template>

<script>
import PromotionEventFlyerGbccTown              from '@/components/gbcc2022/PromotionEventFlyerGbccTown.vue'
import PromotionEventFlyerPreConference         from '@/components/gbcc2022/PromotionEventFlyerPreConference.vue'
import PromotionEventFlyerVirtualKoreaTourism   from '@/components/gbcc2022/PromotionEventFlyerVirtualKoreaTourism.vue'
import PromotionEventFlyerExhibitionSponsorQuiz from '@/components/gbcc2022/PromotionEventFlyerExhibitionSponsorQuiz.vue'
import PromotionEventFlyerLuckyDraw             from '@/components/gbcc2022/PromotionEventFlyerLuckyDraw.vue'


export default {
  name: 'PromotionalEvents',
  components: {
    PromotionEventFlyerGbccTown,
    PromotionEventFlyerPreConference,
    PromotionEventFlyerVirtualKoreaTourism,
    PromotionEventFlyerExhibitionSponsorQuiz,
    PromotionEventFlyerLuckyDraw,
  },
  data () {
    return {
      selectedPromotionIndex: 0,
      promotionalEventsList: [
        {
          name: 'GBCC Town',
          dateString: '2022 April 28-30',
          componentName: 'promotion-event-flyer-gbcc-town'
        },

        {
          name: 'Virtual Korea Tourism',
          dateString: '2022 April 28-30',
          componentName: 'promotion-event-flyer-virtual-korea-tourism'
        },
        {
          name: 'Sponsor Exhibition Quiz',
          dateString: '2022 April 28-30',
          componentName: 'promotion-event-flyer-exhibition-sponsor-quiz'
        },
        {
          name: 'Lucky Draw',
          dateString: '2022 April 30',
          componentName: 'promotion-event-flyer-lucky-draw'
        },
      ],
    }
  },
  computed: {
    selectedFlyerComponent () {
      return this.promotionalEventsList[this.selectedPromotionIndex].componentName
    },
  },
  methods: {
    isSelectedPromotionalEvent (index) {
      return (this.selectedPromotionIndex === index) ? 'selected' : ''
    },
    selectPromotion (index) {
      this.selectedPromotionIndex = index
    },
  },
}
</script>

<style lang='scss' scoped>
  .promotion-navigation-tab {
    @apply border-t-2 border-l-0 border-transparent text-center;
    background-color: #fdf9fb;
    h2 {
      @apply font-thin;
    }
  }

  .promotion-navigation-tab.selected {
    border-left-color: transparent;
    border-top-color: #e393c1;
    background-color: #F6E7F1;
    h2 {
      @apply font-normal;
    }
    h3 {
      @apply opacity-100;
    }
  }

  @media (min-width: 1024px) { 
    .promotion-navigation-tab {
      @apply border-l-4 border-t-0 border-transparent text-left;
    }

    .promotion-navigation-tab.selected {
      border-left-color: #e393c1;
      border-top-color: transparent;
    }
  }



</style>
