<template>
  <div>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/event_img/pre-day-event.png' @click='goToPromotionLink' class='cursor-pointer'  />
  </div>
</template>

<script>
  
export default {
  name: 'PromotionEventFlyerPreConference',
  methods: {
    goToPromotionLink () {
      window.open('https://gbcc-virtual.kr/programs', '_blank')
    },
  },
}
</script>
